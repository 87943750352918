@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Angry";
    src: url('./fonts/ANGRY.OTF');
}

@font-face {
    font-family: "PT Root";
    src: url('./fonts/PTRootUI-Medium.ttf');
}

@font-face {
    font-family: "Inter";
    src: url('./fonts/Inter-Regular.ttf');
}

